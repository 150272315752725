import Swiper, { Autoplay, Pagination } from "swiper";
import Plyr from "plyr";

Swiper.use([Autoplay, Pagination]);

class pageHeader {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.slides = undefined;
    this.slider = undefined;
    this.popup = undefined;
    this.popupTrigger = undefined;
    this.popupClose = undefined;
    this.video = undefined;
  }

  init() {
    this.slides = this.rootEl.querySelector(".page-header__slides");
    this.popup = this.rootEl.querySelector(".page-header__popup");

    if (this.slides) {
      this.slider = new Swiper(this.slides, {
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
        },
        pagination: {
          el: this.slides.querySelector(".page-header__pagination"),
          clickable: true,
          renderBullet: function (index, className) {
            return '<div class="' + className + '"></div>';
          },
        },
      });
    }

    if (this.popup) {
      this.popupTrigger = this.rootEl.querySelector(".page-header__video");
      this.popupClose = this.rootEl.querySelector(".page-header__popup--x");
      this.video = new Plyr(this.rootEl.querySelector(".page-header__popup--video"));

      this.popupTrigger.addEventListener("click", (e) => {
        if (this.popup.classList.contains("closed")) {
          this.popup.classList.remove("closed");
        }
      });

      this.popupClose.addEventListener("click", (e) => {
        if (!this.popup.classList.contains("closed")) {
          this.popup.classList.add("closed");
          this.video.pause();
        }
      });
    }
  }
}

const initPageHeaders = () => {
  const pageHeaders = document.querySelectorAll(".page-header");
  pageHeaders.forEach((block) => new pageHeader(block).init());
};

export default initPageHeaders;
