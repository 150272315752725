class Accordion {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.items = undefined;
    this.itemCollectionOne = undefined;
    this.itemCollectionTwo = undefined;
    this.item = undefined;
    this.activeItemOne = undefined;
    this.activeItemTwo = undefined;
    this.otherItemOne = undefined;
    this.otherItemTwo = undefined;
  }

  init() {
    this.items = this.rootEl.querySelectorAll(".accordion__items");

    if (this.items) {
      this.items.forEach((collection, i) => {
        if (i === 0) {
          this.itemCollectionOne = collection.querySelectorAll(".accordion__item");
        } else {
          this.itemCollectionTwo = collection.querySelectorAll(".accordion__item");
        }
      });

      if (this.itemCollectionOne && this.itemCollectionTwo) {
        this.otherItemOne = this.itemCollectionOne;
        this.itemCollectionOne.forEach((el) => {
          this.itemHandler(el, 1);
        });

        this.otherItemTwo = this.itemCollectionTwo;
        this.itemCollectionTwo.forEach((el) => {
          this.itemHandler(el, 2);
        });
      }
    }
  }

  itemConstructor(el) {
    let itemUpper = el.querySelector(".accordion__item--upper");
    let itemContent = el.querySelector(".accordion__item--content");
    let itemUpperHeight = itemUpper.offsetHeight + "px";
    itemContent.style.maxHeight = itemUpperHeight;
    return itemContent;
  }

  itemHandler(el, int) {
    let itemContent = this.itemConstructor(el);

    el.addEventListener("click", (e) => {
      if (int === 1) {
        this.activeItemOne = el;
        this.otherItemOne.forEach((otherEl) => {
          if (this.otherItemOne != this.activeItemOne) {
            this.itemCloser(otherEl);
          }
        });

        if (!this.activeItemOne.classList.contains("open")) {
          setTimeout(() => {
            this.activeItemOne.classList.add("open");
            itemContent.style.maxHeight = itemContent.scrollHeight + "px";
          }, 200);
        }
      } else {
        this.activeItemTwo = el;
        this.otherItemTwo.forEach((otherEl) => {
          if (this.otherItemTwo != this.activeItemTwo) {
            this.itemCloser(otherEl);
          }
        });

        if (!this.activeItemTwo.classList.contains("open")) {
          setTimeout(() => {
            this.activeItemTwo.classList.add("open");
            itemContent.style.maxHeight = itemContent.scrollHeight + "px";
          }, 200);
        }
      }
    });
  }

  itemCloser(el) {
    this.itemConstructor(el);

    setTimeout(() => {
      el.classList.remove("open");
    }, 200);
  }
}

const initAccordions = () => {
  const accordions = document.querySelectorAll(".accordion");
  accordions.forEach((block) => new Accordion(block).init());
};

export default initAccordions;
