const bodyScrollLock = require("body-scroll-lock");
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

class Menu {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.mobileToggle = undefined;
    this.navItems = undefined;
  }

  init() {
    this.mobileToggle = this.rootEl.querySelector(".menu__nav--burger--wrapper");
    this.navItems = this.rootEl.querySelectorAll(".menu__nav--items");

    this.mobileToggle.addEventListener("click", (e) => {
      if (this.rootEl.classList.contains("open")) {
        this.rootEl.classList.remove("open");
        enableBodyScroll(this.rootEl);
        // enableBodyScroll(this.navItems);
      } else {
        this.rootEl.classList.add("open");
        disableBodyScroll(this.rootEl);
        // disableBodyScroll(this.navItems);
      }
    });

    window.addEventListener("resize", (e) => {
      if (window.innerWidth > 767) {
        if (this.rootEl.classList.contains("open")) {
          this.rootEl.classList.remove("open");
          enableBodyScroll(this.rootEl);
          // enableBodyScroll(this.navItems);
        }
      }
    });

    if (document.documentElement.scrollTop !== 0) {
      this.rootEl.classList.add("sticky");
    }

    let lastScrollTop = 0;
    window.addEventListener(
      "scroll",
      (e) => {
        var st = window.pageYOffset || document.documentElement.scrollTop;

        if (document.documentElement.scrollTop !== 0) {
          this.rootEl.classList.add("sticky");
        } else {
          this.rootEl.classList.remove("sticky");
        }

        if (st > lastScrollTop) {
          if (document.documentElement.scrollTop !== 0) {
            if (window.innerWidth > 767) {
              this.rootEl.classList.add("hidden");
            }
          }
        } else {
          this.rootEl.classList.remove("hidden");
        }
        lastScrollTop = st <= 0 ? 0 : st;
      },
      false
    );
  }
}
const initMenu = () => {
  const menu = document.querySelectorAll(".menu");
  menu.forEach((el) => new Menu(el).init());
};

export default initMenu;
