import { default as blockLoader } from "./blocks/blockLoader";

function init() {
  blockLoader();
  animateIn();
  lazyLoad();
}

function animateIn() {
  let io = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.classList.add("animate-in");
          io.unobserve(entry.target);
        }
      });
    },
    { threshold: 0.2 }
  );

  let blocks = document.querySelectorAll(".section:not(.page-header, .subpage-header)");

  blocks.forEach((block) => {
    io.observe(block);
  });
}

function lazyLoad() {
  var lazyImages = [].slice.call(document.querySelectorAll(".lazy-loaded-image.lazy"));

  let lazyImageObserver = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
      if (entry.isIntersecting) {
        let lazyImage = entry.target;

        lazyImage.style.backgroundImage = lazyImage.dataset.src;
        lazyImage.classList.remove("lazy");
        lazyImageObserver.unobserve(lazyImage);
      }
    });
  });

  lazyImages.forEach(function (lazyImage) {
    lazyImageObserver.observe(lazyImage);
  });
}

window.onload = init;
