class textImage {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.rm = undefined;
    this.rmwrap = undefined;
    this.concat = undefined;
    this.full = undefined;
    this.concatHeight = undefined;
    this.fullHeight = undefined;
  }

  init() {
    this.rm = this.rootEl.querySelector(".text-image__text__rm");

    if (this.rm !== null) {
      this.rmwrap = this.rootEl.querySelector(".text-image__text__rm--click");
      this.concat = this.rootEl.querySelector(".text-image__text--sp--concat");
      this.full = this.rootEl.querySelector(".text-image__text--sp--full");

      this.concatHeight = this.concat.scrollHeight;
      this.full.style.maxHeight = this.concatHeight + "px";

      this.fullHeight = this.full.scrollHeight;
      this.full.classList.add("hidden");

      window.addEventListener("resize", (e) => {
        if (this.full.classList.contains("hidden")) {
          this.full.classList.remove("hidden");
          this.fullHeight = this.full.scrollHeight;
          this.full.classList.add("hidden");

          this.concatHeight = this.concat.scrollHeight;
        } else {
          this.fullHeight = this.full.scrollHeight;
          this.full.style.maxHeight = this.fullHeight + "px";

          this.concat.classList.remove("hidden");
          this.concatHeight = this.concat.scrollHeight;
          this.full.classList.add("hidden");
        }
      });

      if (this.rm) {
        this.rmwrap.addEventListener("click", (e) => {
          if (this.rm.classList.contains("text-image__read-more")) {
            //clicked read more
            this.rm.classList.remove("text-image__read-more");
            this.rm.classList.add("text-image__read-less");
            this.full.classList.remove("hidden");
            this.concat.classList.add("hidden");

            setTimeout(() => {
              this.full.style.maxHeight = this.fullHeight + "px";
            }, 20);

            this.rm.innerHTML = "Read Less";
          } else {
            //clicked read less
            this.rm.classList.remove("text-image__read-less");
            this.rm.classList.add("text-image__read-more");

            setTimeout(() => {
              this.full.classList.add("hidden");
              this.concat.classList.remove("hidden");
            }, 200);

            this.full.style.maxHeight = this.concatHeight + "px";

            this.rm.innerHTML = "Read More";
          }
        });
      }
    }
  }
}

const initTextImage = () => {
  const textImages = document.querySelectorAll(".text-image");
  textImages.forEach((block) => new textImage(block).init());
};

export default initTextImage;
