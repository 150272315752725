import PageHeader from "./pageHeader";
import TextImage from "./textImage";
import Accordion from "./accordion";
import Testimonial from "./testimonial";
import TextImageSlider from "./textImageSlider";
import EntryListing from "./entryListing";
import Form from "./form";
import Menu from "./menu";

export default function () {
  Menu();
  PageHeader();
  TextImage();
  Accordion();
  TextImageSlider();
  Testimonial();
  EntryListing();
  Form();
}
