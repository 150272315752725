class Form {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.formGroups = undefined;
  }

  init() {
    this.formGroups = this.rootEl.querySelectorAll(".form__group");

    this.formGroups.forEach((el) => {
      let input = el.querySelector("input, textarea");

      if (input) {
        input.addEventListener("focusin", (e) => {
          el.classList.add("focused");
        });

        input.addEventListener("focusout", (e) => {
          let inputValue = input.value;

          if (inputValue == "") {
            input.classList.remove("filled");
            el.classList.remove("focused");
          } else {
            input.classList.add("filled");
          }
        });

        input.addEventListener("input", (e) => {
          let inputValue = input.value;

          if (inputValue == "") {
            input.classList.remove("filled");
            el.classList.remove("focused");
          } else {
            input.classList.add("filled");
            el.classList.add("focused");
          }
        });
      }
    });
  }
}
const initForms = () => {
  const forms = document.querySelectorAll(".contact-form, .dynamic-form");
  forms.forEach((el) => new Form(el).init());
};

export default initForms;
