import Swiper, { Autoplay, Navigation, EffectFade } from "swiper";
Swiper.use([Autoplay, Navigation, EffectFade]);

class ImageSlide {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.slides = undefined;
    this.slider = undefined;
  }

  init() {
    this.slides = this.rootEl.querySelector(".ti-slider__swiper");

    if (this.slides) {
      this.slider = new Swiper(this.slides, {
        loop: true,
        speed: 600,
        effect: "fade",
        fadeEffect: {
          crossFade: false,
        },
        autoplay: {
          delay: 4000,
          disableOnInteraction: false,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }
  }
}

const initImageSlider = () => {
  const imageSlides = document.querySelectorAll(".ti-slider");
  imageSlides.forEach((block) => new ImageSlide(block).init());
};

export default initImageSlider;
