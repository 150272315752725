import Swiper, { Autoplay, Pagination } from "swiper";
Swiper.use([Autoplay, Pagination]);

class Testimonial {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.slides = undefined;
    this.slider = undefined;
  }

  init() {
    this.slides = this.rootEl.querySelector(".testimonial__items");

    if (this.slides) {
      this.slider = new Swiper(this.slides, {
        loop: true,
        autoplay: {
          delay: 4000,
          disableOnInteraction: true,
        },
        pagination: {
          el: this.slides.querySelector(".testimonial__pagination"),
          clickable: true,
          renderBullet: function (index, className) {
            return '<div class="' + className + '"></div>';
          },
        },
      });
    }
  }
}
const initTestimonials = () => {
  const testimonials = document.querySelectorAll(".testimonial");
  testimonials.forEach((block) => new Testimonial(block).init());
};

export default initTestimonials;
