import Swiper, { Autoplay } from "swiper";
Swiper.use([Autoplay]);

class EntrySlide {
  constructor(rootEl) {
    this.rootEl = rootEl;
    this.slides = undefined;
    this.slider = undefined;
  }

  init() {
    this.slides = this.rootEl.querySelector(".entry-listing__slider--wrap");

    if (this.slides) {
      this.slider = new Swiper(this.slides, {
        slidesPerView: "auto",
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
      });
    }
  }
}

const entryListing = () => {
  const listingSlides = document.querySelectorAll(".entry-listing");
  listingSlides.forEach((block) => new EntrySlide(block).init());
};

export default entryListing;
